<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Transactions</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      isLoading: (state) => state.creditCards.isLoading,
    }),
  },
  mounted() {
    if (this.patient == null) {
      this.$router.back();
    }
  },
};
</script>
